<template>
  <div style="padding: 100px;border: 1px solid #000;background-color: #fff;overflow: auto;position: relative;">
    <router-link to="/">Home</router-link>
    <hr />
    <marquee truespeed="200">
      <div>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates aspernatur voluptas qui! Consectetur in temporibus facere doloribus suscipit, dignissimos vero ad reprehenderit numquam dolores ipsam pariatur deserunt expedita dolorem delectus?
      </div>
    </marquee>
    <hr>
    {{multy}}
    <Inputs 
      v-model="multy"
      label="name"
      :selectMode="true"
      :apiModule="apiModule"
    />
  </div>
</template>

<script>
import { BUGETARE, RN_OBIECTIVE } from '@/api.js'
import Inputs from "@/components/MultyInputsFields";

export default {
  components: {
    Inputs
  },
  data() {
    return {
      apiModule: BUGETARE.get,
      model: {id: 803, name:'test'},
      multy: ['123'],
      apiModule: (x, y) => RN_OBIECTIVE.get(x, y, `year=2022`)
    }
  },
  methods: {}
}
</script>